import { Navigate } from 'react-router-dom';
import { UserContext } from 'api/Context';

const PrivateRoute = ({Component, forward, userData, updateUserData, isLoggedIn}) => {

    return isLoggedIn ? 
        <UserContext.Provider value={{userData, updateUserData}}>
            <Component />
        </UserContext.Provider>
        : <Navigate to={"/login"} />
}

export default PrivateRoute;
