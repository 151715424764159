import React, { useState, useEffect } from 'react';
import { DialogTitle, DialogContent, ToggleButtonGroup, ToggleButton, FormControl, 
    TextField, DialogActions, Button, Select, Grid, Checkbox, InputLabel, MenuItem } from '@mui/material';
import AxiosRequest from 'api/AxiosRequest';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppleIcon from '@mui/icons-material/Apple';
import ApplePayLogo from 'assets/images/purchase/applepay_logo.svg';
import PaypalLogo from 'assets/images/purchase/paypal_logo.svg';
import CardRedirect from 'assets/images/purchase/card redirect.svg';
import CreditCardImages from 'assets/images/purchase/credit_card_collection.svg';
import validator from "validator";
import NumericInput from 'utils/NumericInput';
import ErrorAlert from 'components/common/ErrorAlert';
import SuccessAlert from 'components/common/SuccessAlert';
import { Elements, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Adjust from '@adjustcom/adjust-web-sdk';
import { gtag } from 'index';
import axios from 'axios';
import Cookies from 'js-cookie';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_1JirO8tDVNEyZJh8FFJ9VkyC87C5tHwUJWhIWtStZ8a1bv6h80MC4pxapeXNeX7hZPsT6cbN4P84zOhPKbwhjUO2G00MzVnRc1Q');

const CompletePurchase = ({ userData, updateUserData, selectedPackage, autoReplenishList, previousCredits, closeDialog }) => {
    const [paymentMethod, setPaymentMethod] = useState("credit-card");
    const [autoReplenishChecked, setAutoReplenishChecked] = useState(false);
    const [autoReplenishPackage, setAutoReplenishPackage] = useState(selectedPackage.sid);
    const [activeAutoReplenisher, setActiveAutoReplenisher] = useState(null);
    const [cardNumber, setCardNumber] = useState("");
    const [expiry, setExpiry] = useState("");
    const [cvc, setCVC] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [validCard, setValidCard] = useState(true);
    const [validCardMessage, setValidCardMessage] = useState(true);
    const [validExpiry, setValidExpiry] = useState(true);
    const [validExpiryMessage, setValidExpiryMessage] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [couponErrorMessage, setCouponErrorMessage] = useState(null);
    const [couponErrorCode, setCouponErrorCode] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [coupon, setCoupon] = useState("");

    const changeMethod = (e, method) => {
        setPaymentMethod(method);
    };

    const ccExpired = (date) => {
        const dateArr = date.split('/');
        const exYear = +dateArr[1] + 2000;
        const exMonth = +dateArr[0] - 1; // JS months start with 0
    
        const exDay = new Date(exYear,exMonth + 1,0).getDate();
        const dateObj = new Date(exYear,exMonth,exDay - 2); // Timezones fix.
        const dateNow = new Date();
        return dateNow > dateObj;
    }

    const changeCardNumber = (e) => {
        if (validator.isCreditCard(e) || e.length < 1) {
            setValidCard(true);
            setValidCardMessage(null);
        } else {
            setValidCard(false);
            setValidCardMessage("Not a valid credit card number.");
        }
    }

    const formatExpirationDate = (value) => {
        const clearValue = NumericInput(value)

        if (clearValue.length >= 3) {
          return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
        }
      
        return clearValue
    }

    const changeExpiry = (input) => {
        const expValue = formatExpirationDate(input);
        if (ccExpired(input)) {
            setValidExpiry(false);
            setValidExpiryMessage("Expiration date must be future date.");
        } else {
            setValidExpiry(true);
            setValidExpiryMessage(null);
        }
        setExpiry(expValue);
    }
    
    const SubmitPurchase = (e) => {
        setSubmitDisabled(true);
        e.preventDefault();
        if (paymentMethod === "credit-card") {
            let exp = "";
            if (expiry) {
                exp = expiry.split("/");
            }
            const data = {
                "coupon_code": coupon,
                "payment_method": paymentMethod,
                "first_name": userData.first_name,
                "last_name": userData.last_name,
                "zip_code": postalCode,
                "card_number": cardNumber,
                "expiration_month": exp[0],
                "expiration_year": "20" + exp[1],
                "ccv": cvc,
                'purchase_amount_sid': selectedPackage.sid,
            };

            AxiosRequest("Transactions", data).then((response) => {
                if (response.data.resources) {
                    Adjust.trackEvent({
                        eventToken: "lg95ge",
                        revenue: response.data.resources.transaction.amount
                    });
                    
                    window.gtag("event", "purchase", {
                        transaction_id: response.data.resources.transaction.sid,
                        value: response.data.resources.transaction.amount,
                        currency: "USD",
                        coupon: response.data.resources.transaction.coupon,
                    });

                    if (Cookies.get('affiliate') && Cookies.get('affiliate') === 'pj' && Cookies.get('pjn_click')) {
                        sessionStorage.setItem("account_action", "replenish");
                        sessionStorage.setItem("transaction_amount", response.data.resources.transaction.amount);
                        sessionStorage.setItem("transaction_sid", response.data.resources.transaction.sid);
                    }

                    if (Cookies.get('uts_lctid')) {
                        let coupon = "";
                        if (response.data.resources.transaction.coupon) {
                            coupon = response.data.resources.transaction.coupon;
                        }

                        return axios({
                            method: "POST",
                            url: "https://us-east1-spoofcard-1335.cloudfunctions.net/linkConnectorCall",
                            data: {
                                "lctid": Cookies.get('uts_lctid'),
                                "oid": response.data.resources.transaction.sid,
                                "amt": response.data.resources.transaction.amount,
                                "lc_coupon": coupon
                            }
                        }).then(res => {
                            console.log(res);
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                }
                
                setSuccessMessage("Thank you for your purchase!");
                setErrorMessage(null);
                setErrorCode(null);
                setTimeout(() => {
                    // resetting all error and success messages/codes when dialog is closed
                    closeDialog();
                    setErrorCode(null);
                    setErrorMessage(null);
                    setSuccessMessage(null);
                }, 2000);
                updateUserData();
                
                UpdateAutoReplenisher();
            })
            .catch(error => {
                setSubmitDisabled(false);
                console.log(error);
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");
                    setErrorCode(error.response.data.error.code);        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            });
        } else if (paymentMethod === "paypal-express") {
            const data = {
                "coupon_code": coupon,
                "payment_method": paymentMethod,
                "return_url": "https://spoofcard.com/buy/paypal/return_action/confirm",
                "cancel_url": "https://spoofcard.com/buy/paypal/return_action/cancel",
                'purchase_amount_sid': selectedPackage.sid,
            };

            AxiosRequest("Transactions", data).then((response) => {            
                window.open(response.data.resources.transaction.gateways[0].redirect_url, '_blank');
                closeDialog();
            })
            .catch(error => {
                console.log(error);
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");
                    setErrorCode(error.response.data.error.code);        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            });
        } else if (paymentMethod === "apple-pay") {
            const data = {
                "payment_method": paymentMethod,
                'purchase_amount_sid': selectedPackage.sid,
                // 'token': $this->_getParam('token'),
                // not sure if token is necessary
            };

            AxiosRequest("Transactions", data).then((response) => {
                setSuccessMessage("Thank you for your purchase!");
                setErrorMessage(null);
                setErrorCode(null);
                setTimeout(() => {
                    // resetting all error and success messages/codes when dialog is closed
                    // closeDialog();
                    setErrorCode(null);
                    setErrorMessage(null);
                    setSuccessMessage(null);
                }, 2000);
                updateUserData();
            })
            .catch(error => {
                console.log(error);
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");
                    setErrorCode(error.response.data.error.code);        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            });
        }
    }

    const UpdateAutoReplenisher = () => {
        let activeAR = 0;
        if (autoReplenishChecked == true) {
            activeAR = 1;
        }
        const arData = {
            'purchase_amount_sid': selectedPackage.sid,
            'balance_threshold': 2.50,
            'is_active': activeAR
        }

        let autoReplenishSid = "";
        if (activeAutoReplenisher) {
            autoReplenishSid = activeAutoReplenisher;
        }

        AxiosRequest("Accounts/AutoReplenishers/" + autoReplenishSid, arData).then((response) => {
            // TODO: add some kind of signifier that auto replenish update was successful
        })
        .catch(error => {
            console.log(error);
        });
    }

    const changeAutoReplenishChecked = () => {
        setAutoReplenishChecked(prevAutoReplenishChecked => !prevAutoReplenishChecked);
    }

    const handleAutoReplenishChange = (event) => {
        setAutoReplenishPackage(event.target.value);
    }

    const SubmitCoupon = () => {
        const data = {
            "purchase_amount_sid": selectedPackage.sid,
            "coupon_code": coupon,
            "page": 1,
            "page_size": 1,
        }

        AxiosRequest("Coupons?purchase_amount_sid=" + data.purchase_amount_sid + "&coupon_code=" + data.coupon_code + 
        "&page="+ data.page +"&page_size=" + data.page, {}, "GET").then((response) => {
            ApplyCoupon(response.data.resources.coupons[0]);
            setCouponErrorMessage(null);
            setCouponErrorCode(null);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status && error.response.status >= 500) {
                setCouponErrorMessage("There was an error. Please try again.");
                setCouponErrorCode(error.response.data.error.code);        
            } else if (error.response.data.error) {
                setCouponErrorMessage(error.response.data.error.message);
                setCouponErrorCode(error.response.data.error.code);
            } else {
                setCouponErrorMessage("There was an error. Please try again.");
            }
        });
    }

    const ApplyCoupon = (coupon_object) => {
        var current_price = selectedPackage.amount;
        var discount = 0.00;
        var discounted_price = current_price;

        if (coupon_object.percentage_discount) {
            discount = current_price * coupon_object.percentage_discount / 100;
        } else if (coupon_object.value_discount) {
            discount = coupon_object.value_discount;
        }

        discount = discount.toFixed(2);
        discounted_price = Math.round((current_price - discount) * 100) / 100;

        document.getElementById('total-credits-summary').textContent = selectedPackage.credits + " Credits";
        document.getElementById('price-summary').textContent = "$" + discounted_price.toFixed(2);
        const couponCodeInfo =  document.getElementById("coupon-code-info")
        couponCodeInfo.innerHTML = '<strong>' + coupon_object.coupon_code + '</strong> ' + "$" + discount + " Discount Applied";
        couponCodeInfo.style.display = "block";
    }

    // check if user has auto replenisher enabled
    useEffect(() => {
        if (userData.plugins.auto_replenishers) {
            if (userData.plugins.auto_replenishers.filter(e => e.is_active).length > 0) {
                setActiveAutoReplenisher(userData.plugins.auto_replenishers.filter(e => e.is_active)[0].sid);
                setAutoReplenishChecked(true);
            } else {
                setAutoReplenishChecked(false);
            }
        }
    }, []);

    const stripeOptions = {
        mode: 'payment',
        amount: Math.round(selectedPackage.amount * 100),
        currency: 'usd',
    };

    const expressCheckoutOptions = {
        layout: {
            overflow: 'never'
        }
    };

    return (
        <form onSubmit={SubmitPurchase}>
            <DialogTitle>Complete your purchase</DialogTitle>
            <DialogContent id="CompletePurchase">
                <ToggleButtonGroup id='payment-method-group' fullWidth
                    value={paymentMethod}
                    exclusive
                    onChange={changeMethod}
                    aria-label="Payment Method"
                    >
                    <ToggleButton value="credit-card">
                        <CreditCardIcon /> 
                        <p>Card</p>
                    </ToggleButton>
                    <ToggleButton value="paypal-express">
                        <img src={PaypalLogo} alt='paypal logo' /> 
                        <p>Paypal</p>
                    </ToggleButton>
                    {/* <ToggleButton value="apple-pay">
                        <p>Other</p>
                        <img src={ApplePayLogo} alt="apple pay logo" /> 
                        <p>Apple Pay</p>
                    </ToggleButton> */}
                </ToggleButtonGroup>
                {paymentMethod === "credit-card" &&
                    <Grid className='purchase-inputs' container spacing={1} direction={"row"}>
                        <Grid item xs={12}>
                            <TextField label="Credit Card" placeholder="1234 1234 1234 1234" variant="outlined" size="medium" 
                                onChange={e => setCardNumber(e.target.value)} 
                                onBlur={e => changeCardNumber(e.target.value)} 
                                fullWidth value={cardNumber}
                                error={!validCard} helperText={!validCard && validCardMessage && validCardMessage}
                                autoComplete='cc-number' name='cc-number' required
                                InputProps={{
                                    endAdornment: <img className='CreditCardImages' src={CreditCardImages} alt='' />
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField label="Expiry" placeholder="MM/YY" variant="outlined" size="medium" fullWidth
                                onChange={e => changeExpiry(e.target.value)} inputProps={{ maxLength: 5 }}
                                value={expiry} required
                                error={!validExpiry} helperText={!validExpiry && validExpiryMessage && validExpiryMessage}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField label="CVC" placeholder="CVC" variant="outlined" size="medium" fullWidth required
                                onChange={e => setCVC(e.target.value)} value={cvc} inputProps={{ maxLength: 4 }} />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField label="Postal code" placeholder="90210" variant="outlined" size="medium" fullWidth
                                onChange={e => setPostalCode(e.target.value)} value={postalCode} required />
                        </Grid>
                    </Grid>
                }
                {paymentMethod === "paypal-express" &&
                    <p className='paypal-text'><img src={CardRedirect} alt='' />
                        After submitting your order, you will be redirected to securely complete your purchase.</p>
                }
                {/* {paymentMethod === "apple-pay" &&
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <ExpressCheckoutElement  />
                    </Elements>
                } */}
            </DialogContent>
            <DialogContent>
                {paymentMethod === "credit-card" &&
                <div id="purchase-auto-replenish">
                    <Checkbox onChange={changeAutoReplenishChecked} checked={autoReplenishChecked} />
                    <FormControl id="purchase-auto-replenish-select">
                        <InputLabel id="auto-replenish-label">Automatically add</InputLabel>
                        <Select
                            labelId="auto-replenish-label" 
                            label="Automatically add" 
                            value={autoReplenishPackage}
                            onChange={handleAutoReplenishChange}
                            className="purchase-auto-replenish-select"
                        >
                            {autoReplenishList && 
                                autoReplenishList.map((p, i) => {
                                    return (
                                        <MenuItem key={i} value={p.sid} >
                                            {p.credits} Credits for ${p.amount}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <span className='auto-replenish-span'>when your balance gets low.</span>
                </div>
                }
                   
                <Grid className='purchase-inputs' container spacing={1} direction={"row"} style={{alignItems: "center", marginBottom: "15px"}}>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Coupon?" variant="outlined" size="medium" fullWidth
                            onChange={e => setCoupon(e.target.value)} value={coupon} />
                    </Grid>   
                    <Grid item xs={12} sm={6}>
                        <Button onClick={SubmitCoupon} variant='text' id='apply-coupon-button'>Apply Coupon</Button>
                    </Grid>
                    {couponErrorMessage &&
                        <Grid item xs={12} sm={12}>
                            <ErrorAlert message={couponErrorMessage} code={couponErrorCode} />
                        </Grid>
                    }
                </Grid>
                
                <div id="purchase-summary">
                    <p id='total-price'>Total Price:</p>
                    <p id="previous-credits-summary">{previousCredits}</p>
                    <span id="total-credits-summary">{selectedPackage.description}</span>
                    <span id="price-summary">${selectedPackage.amount}</span>
                    <p id="coupon-code-info" style={{ display: "none" }}></p>
                </div>
            </DialogContent>
            {(errorMessage || successMessage) &&
                <DialogContent>
                    {errorMessage &&
                    <ErrorAlert message={errorMessage} code={errorCode} />
                    }
                    {successMessage &&
                    <SuccessAlert message={successMessage} />
                    }
                </DialogContent>
            }
            <DialogActions>
                {paymentMethod === "credit-card" &&
                <Button type='submit' variant='contained' disabled={submitDisabled}
                    style={{ backgroundColor: "rgba(61, 203, 85, 1)"}}>
                    Complete Purchase
                </Button>
                }
                {paymentMethod === "paypal-express" &&
                <Button type='submit' variant='contained' 
                    style={{ backgroundColor: "rgba(61, 203, 85, 1)"}}>
                    Continue to PayPal
                </Button>
                }
                {paymentMethod === "apple-pay" &&
                <Elements stripe={stripePromise} options={stripeOptions}>
                    <ExpressCheckoutElement options={expressCheckoutOptions} />
                </Elements>
                // <Button type='submit' variant='contained' startIcon={<AppleIcon />}
                //     style={{ backgroundColor: "#000", color: "#fff"}}>
                //     Buy with Apple Pay
                // </Button>
                }
            </DialogActions>
        </form>
    );
};

export default CompletePurchase;
