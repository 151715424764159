import React, { useState, useContext } from 'react';
import { Dialog, IconButton } from '@mui/material';
import SelectPackage from 'components/views/Purchase/SelectPackage';
import CompletePurchase from 'components/views/Purchase/CompletePurchase';
import CloseIcon from '@mui/icons-material/Close';
import { PackagesContext } from 'api/Context';

const Purchase = ({ open, closeDialog, userData, updateUserData }) => {
    const {packages} = useContext(PackagesContext);
    const [activeStep, setActiveStep] = useState(1);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [previousCredits, setPreviousCredits] = useState(null);

    const clickPackage = (packageObject, prevCredits) => {
        setSelectedPackage(packageObject);
        if (prevCredits) {
            setPreviousCredits(prevCredits);
        }
        setActiveStep(2);
    }

    let prevCreditsProp = "";
    if (previousCredits) {
        prevCreditsProp = previousCredits;
    }

    return (
        <Dialog open={open} onClose={closeDialog} id="PurchaseDialog">
            <IconButton edge="start" color="inherit" onClick={closeDialog} className='close-button'>
                <CloseIcon />
            </IconButton>
            {activeStep === 1 && 
            <SelectPackage userData={userData} clickPackage={clickPackage} packages={packages} />
            }
            {activeStep === 2 && 
            <CompletePurchase userData={userData} updateUserData={updateUserData} selectedPackage={selectedPackage} 
                autoReplenishList={packages} previousCredits={prevCreditsProp} closeDialog={closeDialog} />
            }
        </Dialog>
    );
}

export default Purchase;