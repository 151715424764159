import React, { useState } from 'react';
import { DialogTitle, DialogContent, Button, Stack } from '@mui/material';
import { Loader } from 'components/common/Loader';

const SelectPackage = ({ clickPackage, packages }) => {
    const [previousPackages, setPreviousPackages] = useState(null);

    return (
        <>
            <DialogTitle>Select Package</DialogTitle>
            <DialogContent id="SelectPackage" style={{borderBottom: "1px solid rgba(0, 0, 0, 0.12)"}}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} 
                    className={'sale-packages desktop'}>
                    {packages && previousPackages && packages.map((p, i) => {
                        let previousCredits = "";
                        if (p.amount === previousPackages[i].amount) {
                            // previousCredits = previousPackages[i].description;
                            previousCredits = previousPackages[i].description;
                        }
                        return (
                            <div className="package has-previous-credits" key={i}>
                                <p className='package-amount'>${p.amount}</p>
                                <p className='previous-credits'>{previousCredits}</p>
                                <p>{p.description}</p>
                                <Button onClick={() => clickPackage(p, previousCredits)} variant="contained" className="green-button">Buy</Button>
                            </div>
                        )})
                    }
                    {packages && !previousPackages && packages.map((p, i) => {
                        let description = ""
                        if (p.category === "corporate") {
                            description = p.credits + " Credits"
                        } else {
                            description = p.description;
                        }
                        return (
                            <div className="package" key={i}>
                                <p className='package-amount'>${p.amount}</p>
                                <p>{description}</p>
                                <Button onClick={() => clickPackage(p)} variant="contained" className="green-button">Buy</Button>
                            </div>
                        )})
                    }
                    {!packages &&
                        <Loader />
                    }
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} className='sale-packages mobile'>
                    {packages && previousPackages && packages.map((p, i) => {
                        let previousCredits = "";
                        if (p.amount === previousPackages[i].amount) {
                            previousCredits = previousPackages[i].description;
                        }
                        return (
                            <div className="package has-previous-credits" key={i}>
                                <p>{p.description}</p>
                                <p className='previous-credits'>{previousCredits}</p>
                                <Button onClick={() => clickPackage(p, previousCredits)} variant="contained" className="green-button">
                                    Buy For ${p.amount}</Button>
                            </div>
                        )})
                    }
                    {packages && !previousPackages && packages.map((p, i) => {
                        return (
                            <div className="package" key={i}>
                                <p>{p.description}</p>
                                <Button onClick={() => clickPackage(p)} variant="contained" className="green-button">
                                    Buy For ${p.amount}</Button>
                            </div>
                        )})
                    }
                    {!packages &&
                        <Loader />
                    }
                </Stack>
                <p style={{fontSize: "14px", margin: "15px 0 0"}}>Credits are used for SpoofCard calls and other features. 
                    The majority of calls only cost one credit per minute. 
                    International destinations outside of North America may cost more.</p>
            </DialogContent>
        </>
    );
};

export default SelectPackage;
