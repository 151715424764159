import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Paper, Link } from '@mui/material';
// import LoginBg from 'assets/images/background/login_bg.png';
import LoginBg from 'assets/images/background/login_bg.webp';
import 'assets/css/Login.css';
import CreateAccount from './CreateAccount';
import Verification from 'components/common/Verification/Verification';

const SignUp = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [createAccountProps, setCreateAccountProps] = useState(null);

    // passing verification data and verification receipt back from VerificationVerify.js for use
    let createAccountData = {};
    let createAccountReceipt = "";
    if (createAccountProps) {
        if (createAccountProps.verificationData) {
            createAccountData = createAccountProps.verificationData;
        }
        if (createAccountProps.receipt) {
            createAccountReceipt = createAccountProps.receipt;
        }
    }

    return (
        <div className='login-signup'>
            <Grid container spacing={2} style={{textAlign: "left"}} direction={"row"}>
                <Grid item md={6} className="login-bg">
                    <div>
                        <h2>Protect your privacy with <br/><span>SpoofCard.</span></h2>
                        <img src={LoginBg} alt="" />
                    </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12} id='login-paper'>
                    <Paper elevation={12}>
                        {activeStep && activeStep === 1 &&
                        <>
                        <h2>Claim your free introductory credits!</h2>
                        <p>Sign up and try all of our features for free.</p>
                        </>
                        }
                        <Verification scope={"accounts.create"} getActiveStep={setActiveStep} 
                            getProps={setCreateAccountProps}
                            comp={<CreateAccount verificationData={createAccountData} receipt={createAccountReceipt} />}
                        />
                        <div className='login-options'>
                            {activeStep && activeStep === 1 &&
                            <p>By tapping next you agree to our <a href='https://spoofcard.com/terms-of-service' 
                                target='_blank'>Terms of Service</a> and <a href="https://spoofcard.com/privacy" 
                                target='_blank'>Privacy Policy</a></p>
                            }
                            <p>Already have an account? 
                                <NavLink to='/login' id="sign-up-login" className="signup-login-switch">Log In</NavLink>
                            </p>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default SignUp;