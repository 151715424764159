import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, FormControl, Button, Select, Grid, InputLabel, MenuItem } from '@mui/material';
import AutoReplenishImg from 'assets/images/auto-replenish.webp';
import AutoReplenishMobileImg from 'assets/images/auto-replenish-mobile.webp';
import AxiosRequest from 'api/AxiosRequest';
import Cookies from 'js-cookie';
import CloseIcon from '@mui/icons-material/Close';

const AutoReplenishDialog = ({open, closeDialog, autoReplenishPackages, selectedPackage, handleAutoReplenishChange}) => {

    const UpdateAutoReplenisher = (e) => {
        e.preventDefault();
        
        const arData = {
            'purchase_amount_sid': selectedPackage,
            'balance_threshold': 2.50,
            'is_active': 1
        }

        AxiosRequest("Accounts/AutoReplenishers/", arData).then((response) => {
            document.getElementById('enable-auto-replenish-button').innerHTML = "Saved!";
            setTimeout(() => {
                SetAutoReplenishCookie();
            }, 3000);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const SetAutoReplenishCookie = () => {
        Cookies.set("AutoReplenishDialog", "viewed", {
            expires: 45,
        });
        closeDialog();
    }

    return (
        <Dialog open={open} onClose={SetAutoReplenishCookie} id="AutoReplenishDialog" fullWidth={true} maxWidth={"sm"}>
            <IconButton edge="start" color="inherit" onClick={SetAutoReplenishCookie} className='close-button'>
                <CloseIcon />
            </IconButton>
            <DialogTitle>Better safe than sorry!</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{textAlign: "left"}} direction={"row"}>
                    <Grid item md={6} xs={12} sm={6}>
                        <img src={AutoReplenishImg} className='desktop' />
                        <img src={AutoReplenishMobileImg} className='mobile-img' />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <div>
                            {/* <h2 style={{ marginTop: "0" }}>Better safe than sorry!</h2> */}
                            <p>Never run out of credits in the middle of your call or text conversation. 
                                Turn on auto-replenish & we'll automatically add credits when your balance 
                                falls below 15 credits. You can disable auto-replenish at any time in your 
                                SpoofCard account settings.</p>
                                <form onSubmit={UpdateAutoReplenisher}>
                                    <FormControl fullWidth>
                                        <InputLabel id="auto-replenish-label">Package</InputLabel>
                                        <Select
                                            labelId="auto-replenish-label" 
                                            label="Package" 
                                            value={selectedPackage}
                                            onChange={handleAutoReplenishChange}
                                            className="auto-replenish-select"
                                        >
                                            {autoReplenishPackages && 
                                                autoReplenishPackages.map((p, i) => {
                                                    return (
                                                        <MenuItem key={i} value={p.sid} >
                                                            {p.credits} Credits for ${p.amount}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <div className='update-auto-replenish-controls'>
                                        <Button type='submit' variant='contained' id='enable-auto-replenish-button' 
                                            fullWidth>Enable Auto-Replenish</Button>
                                    </div>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AutoReplenishDialog;